import React from 'react'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import './src/styles/global.css'
import './src/templates/zero.css'

export const wrapRootElement = ({ element }) => {
    return (
        <GoogleReCaptchaProvider reCaptchaKey={process.env.GATSBY_RECAPTCHA_SITE_KEY}>
            {element}
        </GoogleReCaptchaProvider>
    )
}